// @flow

import React from "react";
import type {PayPalPaymentResponse} from "../../../models/PayPalPaymentResponse";
import "./PayPalButton.scss";
import type Price from "../../../models/Price";
import ReactDOM from "react-dom";
import {prepareOrder} from "../../../actions/order";
import store from "../../../store";
import type {Order, OrderItemsResponse} from "../../../models/Order";
import OrderProcessingApi from "../../../api/OrderProcessingApi";
import type {translate} from "../../../i18next";

export type PayPalButtonProps = {
	onSuccess: () => void,
	onCancel: () => void,
	onFailure: () => void,
	t: translate,
	paypalAdress: number,
	clientId: string,
	payee?: string,
	total: Price,
	paymentOptions: {
		intent: "authorize",
	},
	orderNo: string,
	dealerNo: string,
	createExpressOrder: OrderItemsResponse => void,
	setPaymentError: () => void,
	moduleLanguage?: string,
}

const loadPayPalScript = (clientId, currency, callback, moduleLanguage) => {
	const existingScript = document.getElementById("sl-paypalScript");

	if (!existingScript) {
		const script = document.createElement("script");
		script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&locale=${moduleLanguage}&intent=authorize&commit=false&components=buttons,payment-fields,marks,funding-eligibility&enable-funding=p24`;
		script.id = "sl-paypalScript";
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	} else {
		if (existingScript && callback) {
			if (!window.paypal) {
				existingScript.onload = () => {
					callback();
				};
			} else {
				callback();
			}
		}
	}
};

class PayPalExpressButton extends React.Component<PayPalButtonProps> {
	constructor(props) {
		super(props);
		this.state = {
			scriptLoaded: false
		};
	}

	componentDidMount() {
		loadPayPalScript(
			this.props.clientId,
			this.props.total.currency,
			() => {
				this.setState({
					scriptLoaded: true
				});
			},
			this.props.moduleLanguage
		);
	}

	createOrder(data, actions) {
		const order: Order = prepareOrder(store.getState, false);
		const orderProcessingApi = new OrderProcessingApi();
		return orderProcessingApi.createOrder(order)
			.then(response => {
				this.props.createExpressOrder(response);
				return this.createPayPalOrder(data, actions, response.orderNo);
			})
			.catch(e => {
				/* eslint-disable */
				console.log(order);
				console.log("Error creating express order!", e);
				this.props.setPaymentError();
			});
	}

	createPayPalOrder(data, actions, orderNo: string) {
		function round(x) {
			return Number(Math.round(x + "e2") + "e-2");
		}
		const payPalOrder = this.props.payee
			? {
				purchase_units: [{
					reference_id: orderNo || this.props.orderNo,
					amount: {
						value: round(this.props.total.value)
					},
					payee: this.props.payee
				}]
			}
			: {
				purchase_units: [{
					reference_id: this.props.orderNo,
					amount: {
						value: round(this.props.total.value)
					}
				}]
			};

		return actions.order.create(payPalOrder);
	}

	onApprove(data: any, actions: any) {
		actions.order.authorize().then((details: PayPalPaymentResponse) => {
			this.props.onSuccess(details);
		});
	}

	onError(err) {
		/* eslint-disable-next-line */
		console.log("paypal error: ", err);
		this.props.setPaymentError();
	}

	onCancel = (data, actions) => {
		/* eslint-disable-next-line */
		console.log(this.props.t("cart.paymentMethods.paymentWindowClosed"));
		alert(this.props.t("cart.paymentMethods.paymentWindowClosed"));
	};

	render() {
		if (this.state.scriptLoaded) {
			let Button = window.paypal.Buttons.driver("react", {React, ReactDOM});
			return <Button
				createOrder={(data, actions) => this.createOrder(data, actions)}
				onApprove={(data, actions) => this.onApprove(data, actions)}
				onCancel={(data, actions) => this.onCancel(data)}
				onError={err => this.onError(err)}
				style={{
					layout: "horizontal",
					label: "pay"
				}}
			/>;
		}
		return null;
	}
}

export default PayPalExpressButton;
