// @flow

const EVENT = {
	CATEGORIES: {
		SHOP: "Shop",
		FILTER: "Filter",
		PRODUCT: "Product",
		CART: "Warenkorb",
		SEARCH: "Search",
		BOOKMARKS: "Bookmarks",
		CHECKOUT: "Checkout"
	},
	ACTIONS: {
		CHECKOUT: {
			VISITED_CART: "Cart visited",
			ENTERED_CHECKOUT: "Checkout started",
			ENTERED_ADDRESS: "Entered Address",
			SELECTED_PAYMENT_METHOD: "Selected payment method",
			ORDER_COMPLETE: "Order completed",
		},
		SHOP: {
			LOADED: "Shop loaded"
		},
		FILTER: {
			CATEGORY: "Filtered by Category",
			VEHICLE: "Filtered by Vehicle",
			CAMPAIGN: "Filtered by Campaign",
		},
		BOOKMARKS: {
			BOOKMARKS_SHARED: "Bookmarks sharded",
			ADD_PRODUCT: "Product added to Bookmarks",
			REMOVE_PRODUCT: "Produkt removed from Bookmarks",
		},
		PRODUCT: {
			PRODUCT_VISITED: "Product clicked",
			CROSS_SELLING_PRODUCT_VISITED: "Product visited via cross selling",
			PRODUCT_QUESTION: "Product ask a question clicked",
			PRODUCT_SHARED: "Product shared"
		},
		SEARCH: {
			QUERY: "search query submitted"
		},
		CART: {
			ADD_PRODUCT: "Product added to cart",
			REMOVE_PRODUCT: "Product removed from cart"
		}
	},
	LABEL: {
		FILTER_RESET: "reset filter"
	}
};

export default EVENT;
