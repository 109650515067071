// @flow
import React, { useEffect } from "react";
import type { translate } from "../../../i18next";
import "./SpsCouponCode.scss";
import { toast } from "react-toastify";
import type { Coupon, SpsCustomer } from "../../../reducers/couponReducer";

type SpsCouponCodeProps = {
  t: translate,
  coupon: ?Coupon,
  couponErrorCode: ?string,
  couponSuccessCode: ?string,
  resetCoupon: () => void,
  setSpsCoupon: () => void,
  resetCouponToast: () => void,
  spsCustomer: SpsCustomer,
};

const SpsCouponCode = ({
                         coupon,
                         couponErrorCode,
                         couponSuccessCode,
                         resetCoupon,
                         resetCouponToast,
                         setSpsCoupon,
                         t,
                         spsCustomer
                       }: SpsCouponCodeProps) => {

  useEffect(() => {
    if (couponErrorCode) {
      toast.error(t("cart.coupon." + couponErrorCode));
      resetCouponToast();
    }
    if (couponSuccessCode) {
      toast.success(t("cart.coupon." + couponSuccessCode));
      resetCouponToast();
    }
  }, [coupon, couponErrorCode, resetCoupon, couponSuccessCode, resetCouponToast, t]);


  const handleChange = e => {
    if (e.target.checked) {
      setSpsCoupon();
    } else {
      resetCoupon();
    }
  };

  return (
	<div className={"hpm-spsCouponCodeBox"}>
		<div className={"accountPointsInfo"}>
			<div className="switch-checkbox">
				<label className="switch">
					<input
                          type="checkbox"
                          checked={!!coupon}
                          onChange={handleChange}
                          className="hpm-couponButton"
                      />
					<span className="sps-checkbox round"></span>
				</label>
				<div className={"coupon-text"}>{t("cart.coupon.yourDeposit", { deposit: spsCustomer.deposit.toFixed(2).replace(".", ",") })}</div>
			</div>
			<div className="useCouponText">
				<span>{t("cart.coupon.useCoupon")}</span>
				<span>{t("cart.coupon.willRemain")}</span>
			</div>
		</div>
	</div>
  );
};

export default SpsCouponCode;
