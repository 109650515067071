// @flow
import * as React from "react";
import Spinner from "react-spinkit";
import type {DealerType} from "./Dealer";
import {Dealer} from "./Dealer";
import Map from "./Map";
import "./GarageDetail.scss";
import {Trans} from "react-i18next";
import Configuration from "../../Configuration";
import ProductApi from "../../api/ProductApi";
import Loading from "../../components/layout/Loading";
import {usePosition} from "../../preview/usePosition";
import {FILTER_TYPE} from "../../preview/GarageDetail";

type Props = {
	dealerId: string,
	role: string,
	theme: string,
	openDealerShopsInNewTab: boolean,
	t: string => string,
	articleNumbers: string[],
};

export const GarageDetail = ({articleNumbers, dealerId, openDealerShopsInNewTab, role, t, theme}: Props) => {
	const [garageDetail, setGarageDetail] = React.useState(undefined);
	const [filteredDealers, setFilteredDealers] = React.useState([]);
	const [searchString, setSearchString] = React.useState("");
	const [showMap, setShowMap] = React.useState(true);
	const [dealerSearchType, setDealerSearchType] = React.useState("");
	const {latitude, longitude, error} = usePosition();

	React.useEffect(() => {
		const locationOptions = latitude ? {
			latitude,
			longitude,
			searchRadius: 10000
		} : null;
		const productApi = new ProductApi(dealerId,
			{configIdType: role, intent: "DEFAULT", locationOptions}
		);
		productApi.getDealerForArticleNo(articleNumbers)
			.then(res => setGarageDetail(res));
	}, [articleNumbers, dealerId, latitude, longitude, role]);

	React.useEffect(() => {
		if (!garageDetail) {
			return;
		}
		setFilteredDealers(
			garageDetail.dealersForBrand.filter(dealer => {
				const fuzzy = searchString.toLowerCase();
				const {name, address, dealerConfiguration} = dealer;
				const {shopEnabled, productPricesDisabled} = dealerConfiguration;
				if (dealerSearchType === FILTER_TYPE.WITHOUT_PRICE && (shopEnabled || !productPricesDisabled)) {
					return false;
				}
				if (dealerSearchType === FILTER_TYPE.WITH_PRICE && (shopEnabled || productPricesDisabled)) {
					return false;
				}
				if (dealerSearchType === FILTER_TYPE.WITH_SHOP && (!shopEnabled)) {
					return false;
				}
				if (address && fuzzy) {
					const filterObject = [name, address.town, address.street, address.countryCode, address.streetNumber, address.postalCode];
					return filterObject.some(it => it && it.toLowerCase().includes(fuzzy));
				}
				return true;
			})
		);
	}, [searchString, dealerSearchType, garageDetail]);

	if (!garageDetail) {
		return <div className={"GeoLocator info"}>
			<Spinner name="ball-beat" className="hpm-spinnerButton" fadeIn="none"/>
		</div>;
	}

	const toggleSearchDealerType = dealerType => {
		dealerType !== dealerSearchType
			? setDealerSearchType(dealerType)
			: setDealerSearchType("");
	};

	return (
		<React.Fragment>
			<div className="page-content-block">
				<div className="page-middle">
					<div className={"GeoLocator control"}>
						<span>
							<input type="search" list={"dealers"} value={searchString}
								   placeholder={t("nsc.search")}
								   onChange={e => setSearchString(e.target.value)}/>
							<label className="ico search" htmlFor="dealer-class-10"/>
						</span>
						<div className="geolocatorLegend">
							<div
								className={"indicator location " + (dealerSearchType === FILTER_TYPE.WITHOUT_PRICE ? " checked" : "")}
								onClick={() => toggleSearchDealerType(FILTER_TYPE.WITHOUT_PRICE)}>{t("nsc.offer.withoutPrice")}</div>
							<div
								className={"indicator euro " + (dealerSearchType === FILTER_TYPE.WITH_PRICE ? " checked" : "")}
								onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_PRICE)}>{t("nsc.offer.withPrice")}</div>
							{theme === "ford"
								? <div
									className={"indicator shop " + (dealerSearchType === FILTER_TYPE.WITH_SHOP ? " checked" : "")}
									onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_SHOP)}>{t("nsc.offer.withShopFord")}</div>
								: <div
									className={"indicator shop " + (dealerSearchType === FILTER_TYPE.WITH_SHOP ? " checked" : "")}
									onClick={() => toggleSearchDealerType(FILTER_TYPE.WITH_SHOP)}>{t("nsc.offer.withShop")}</div>
							}
						</div>
						{
							<button type={"button"} onClick={() => setShowMap(!showMap)}>{
								showMap ? <Trans i18nKey="nsc.closeMap"/> : <Trans i18nKey="nsc.openMap"/>
							}</button>
						}
					</div>
					{showMap && <Map
						longitude={longitude}
						latitude={latitude}
						error={error}
						dealers={filteredDealers}
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Configuration.value("googleMapsApiKey")}&v=3.exp&libraries=places`}
						loadingElement={<Loading/>}
						containerElement={<div className={"GeoLocator Map"}/>}
						mapElement={<div/>}
						openDealerShopsInNewTab={openDealerShopsInNewTab}
					/>}
					<div className={"GeoLocator List"}>
						{filteredDealers.map((dealer: DealerType) => <Dealer
							key={dealer.identifier.dealerNo} dealer={dealer}
							articleNumbers={articleNumbers}
							openDealerShopsInNewTab={openDealerShopsInNewTab}
						/>)}
						<div className={"dealer ph"}/>
						<div className={"dealer ph"}/>
						<div className={"dealer ph"}/>
						<div className={"dealer ph"}/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
