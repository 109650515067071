/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React from "react";
import PriceOverviewContainer from "../../../container/checkout/dataSummaryBox/PriceOverviewContainer";
import CartItemOverviewContainer from "../../../container/checkout/mainContentBox/OrderSummaryCartItemContainer";
import type {translate} from "../../../i18next";
import type {Item} from "../../../models/Item";
import type {Adress} from "../../../models/Adress";
import {PAYMENT_METHODS, PAYMENT_PROVIDER} from "../../../models/PaymentRequests";
import "./OrderSummary.scss";
import PickupLocationInfoContainer from "../../../container/pickupLocation/PickupLocationInfoContainer";
import {initialState} from "../../layout/Footer";
import Modal from "react-modal";
import type {PaymentFacility} from "../../../api/PaymentApi";

type OrderOverviewProps = {
	items: Item[],
	shipping: number,
	nextPage: () => void,
	setPage: number => void,
	submitOrder: () => void,
	shippingText: string,
	adress: Adress,
	payment: any,
	t: translate,
	withPickup: boolean,
	showPickupLocation: boolean,
	legalTerms: string,
	dealerId: string,
	notificationUrl: string,
	paymentFacility: PaymentFacility,
	renderP24Button: boolean,
};

type OrderOverviewState = {
	accept: boolean,
	showLegalTerms: boolean
};

class OrderSummary extends React.Component<OrderOverviewProps, OrderOverviewState> {
	constructor(props) {
		super(props);
		this.state = {
			accept: false,
			showLegalTerms: false
		};
	}

	toggleAGB = () => {
		this.setState({...initialState, showLegalTerms: !this.state.showLegalTerms});
	};

	getPayment = () => {
		switch (this.props.paymentFacility.paymentMethod) {
			case PAYMENT_METHODS.PAYPAL:
			case PAYMENT_METHODS.PAYPAL_EXPRESS:
				return <span className="hpm-paymentcarts hpm-paypal">{this.props.t("cart.paymentMethods.paypal")}</span>;
			case PAYMENT_METHODS.CASH:
				return <span className="hpm-paymentcarts hpm-euro">{this.props.t("cart.paymentMethods.cash")}</span>;
			case PAYMENT_METHODS.PREPAID:
				return <span className="hpm-paymentcarts hpm-bankWire">{this.props.t("cart.paymentMethods.prepaid")}</span>;
			case PAYMENT_METHODS.PRZELEWY24:
				return <span className="hpm-paymentcarts hpm-przelewy24">{this.props.t("cart.paymentMethods.przelewy24")}</span>;
			default:
				return null;
		}
	};

	handleChange = () => {
		this.setState({
			accept: !this.state.accept
		});
	};

	renderShippingAdress() {
		if (this.props.adress.shippingAdress) {
			return (
				<div className={"hpm-adressBox"}>
					<span className="hpm-title">{this.props.t("cart.personalInformation.shippingAdress")}</span>
					<span className={"hpm-edit"}
					  onClick={() => this.props.setPage(1)}>{this.props.t("cart.change")}</span>
					<table>
						<tbody>
							<tr>
								<td>{this.props.adress.shippingName} {this.props.adress.shippingFamilyname}</td>
							</tr>
							<tr>
								<td>{this.props.adress.shippingStreet}</td>
							</tr>
							<tr>
								<td>{this.props.adress.shippingPostalCode} {this.props.adress.shippingCity}</td>
							</tr>
						</tbody>
					</table>
				</div>
			);
		}
	}

	render() {
		const modalStyles = {
			overlay: {
				zIndex: "100000"
			},
			content: {
				top: "50%",
				left: "50%",
				right: "auto",
				bottom: "auto",
				marginRight: "-50%",
				transform: "translate(-50%, -50%)",
				border: "1px solid grey",
				width: "calc(90%)",
				maxWidth: "1000px",
				minHeight: "200px",
				maxHeight: "90vh",
				overflowY: "auto",
			}
		};

		const downloadButtonStyles = {
			display: "flex",
			justifyContent: "end",
			marginRight: "3em"
		};

		return (
			<div className={"hpm-orderSummary"}>
				<div className={"hpm-cartContainer"}>
					<div className="hpm-mainContentBox">
						<div className={"hpm-cartTitle"}>{this.props.t("cart.cartLabel")}</div>
						<ul className="hpm-cart">
							{
								this.props.items.map((item, index) => {
									return <CartItemOverviewContainer key={index} cartItem={item}/>;
								})
							}
						</ul>
						<div className="hpm-box hpm-shipping">
							<span
								className="hpm-title">{this.props.t("cart.expectedDeliveryDate.expectedDeliveryDateLabel")}</span>
							<table>
								<tbody>
									<tr>
										<td>{this.props.shippingText}</td>
									</tr>
								</tbody>
							</table>
						</div>
						{this.props.showPickupLocation &&
						<div className="hpm-box">
							<span className="hpm-title">
								{this.props.withPickup ? this.props.t("cart.articlePickupLocation") : this.props.t("cart.articleMountLocation")}
							</span>
							<div>
								<PickupLocationInfoContainer/>
							</div>
						</div>
						}
						{this.props.paymentFacility.paymentProvider &&
							<div className="hpm-box hpm-payment">
								<span
									className="hpm-title">{this.props.t("cart.paymentMethods.selectedPaymentMethod")}
								</span>
								<table>
									<tbody>
										<tr>
											<td>
												{this.getPayment()}
											</td>
										</tr>
										{ this.props.paymentFacility.paymentProvider === PAYMENT_PROVIDER.PREPAID &&
											<React.Fragment>
												<tr>
													<td></td>
												</tr>
												<tr className={"hpm-payment-notice"}>
													<td>
														{this.props.t("cart.shipping.prepaidNotice")}
													</td>
												</tr>
											</React.Fragment>
										}
									</tbody>
								</table>
							</div>
						}
						{this.props.legalTerms &&
							<div className="hpm-box hpm-legalTerms">
								{this.props.t("cart.calculation.legalTerms.infoTextPartOne")}
								<a onClick={() => this.toggleAGB()}>
									{this.props.t("cart.calculation.legalTerms.linkText")}
								</a>
								{this.props.t("cart.calculation.legalTerms.infoTextPartTwo")}
							</div>
						}
						<Modal isOpen={this.state.showLegalTerms}
							   style={modalStyles}
							   shouldCloseOnOverlayClick={true}
							   onRequestClose={this.toggleAGB}
						>
							<button type={"button"} onClick={this.toggleAGB} className="hpm-modalClose"/>
							<div style={downloadButtonStyles}>
								<a className={"file-download-button"}
								   download={this.props.t("cart.calculation.legalTerms.fileName")}
								   id="file-download-button"
								   href={`${this.props.notificationUrl}/download/legalTerms/${this.props.dealerId}`}>{this.props.t("cart.calculation.legalTerms.button")}
								</a>
							</div>
							<div dangerouslySetInnerHTML={{__html: this.props.legalTerms}}
								 className="hpm-modalContent" style={{fontFamily: "var(--font100)"}}/>
						</Modal>
					</div>

					<div className="hpm-dataSummaryBox">
						<div className="hpm-box">
							<div className={"hpm-adressBox"}>
								<span
									className="hpm-title">{this.props.t("cart.personalInformation.billingAdress")}</span>
								<span className={"hpm-edit"} onClick={() => this.props.setPage(1)}>
									{this.props.t("cart.change")}
								</span>
								<table>
									<tbody>
										<tr>
											<td>
												{this.props.adress.name} {this.props.adress.familyname}
											</td>
										</tr>
										<tr>
											<td>
												{this.props.adress.street}
											</td>
										</tr>
										<tr>
											<td>
												{this.props.adress.postalCode} {this.props.adress.city}
											</td>
										</tr>
										{this.props.adress.phone
											? <tr>
												<td>✆ {this.props.adress.phone}</td>
											</tr> : null
										}
										{this.props.adress.email
											? <tr>
												<td>✉ {this.props.adress.email}</td>
											</tr> : null
										}
									</tbody>
								</table>
							</div>
							{this.renderShippingAdress()}
						</div>
						<PriceOverviewContainer
							toggleAccept={() => this.handleChange("accept")}
							accept={this.state.accept}
							submit={() => this.props.submitOrder()}
							specialNextLabel={this.props.t("cart.calculation.submit")}
							renderP24Button={this.props.renderP24Button}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderSummary;
