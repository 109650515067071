// @flow

const EVENT = {
	CATEGORIES: {
		SHOP: "Shop",
		FILTER: "Filter",
		PRODUCT: "Produkt",
		CART: "Warenkorb",
		SEARCH: "Suche",
		BOOKMARKS: "Merkliste",
		CHECKOUT: "Checkout"
	},
	ACTIONS: {
		CHECKOUT: {
			VISITED_CART: "Warenkorb aufgerufen",
			ENTERED_CHECKOUT: "Checkout gestartet",
			ENTERED_ADDRESS: "Adresseingabe abgeschlossen",
			SELECTED_PAYMENT_METHOD: "Zahlungsart ausgewählt",
			ORDER_COMPLETE: "Bestellung abgeschlossen",
		},
		SHOP: {
			LOADED: "Shop geladen"
		},
		FILTER: {
			CATEGORY: "Kategorie gefiltert",
			VEHICLE: "Fahrzeug gefiltert",
			CAMPAIGN: "Kampagne gefiltert",
		},
		BOOKMARKS: {
			BOOKMARKS_SHARED: "Merkliste verschickt",
			ADD_PRODUCT: "Produkt zur Merkliste hinzugefügt",
			REMOVE_PRODUCT: "Produkt von Merkliste entfernt",
		},
		PRODUCT: {
			PRODUCT_VISITED: "Produkt angeklickt",
			CROSS_SELLING_PRODUCT_VISITED: "Auf Crossselling-Produkt geklickt",
			PRODUCT_QUESTION: "Frage zum Produkt gestellt",
			PRODUCT_SHARED: "Produkt geteilt"
		},
		SEARCH: {
			QUERY: "Suche ausgeführt"
		},
		CART: {
			ADD_PRODUCT: "Produkt zum Warenkorb hinzugefügt",
			REMOVE_PRODUCT: "Produkt von Warenkorb gelöscht"
		}
	},
	LABEL: {
		FILTER_RESET: "zurückgesetzt"
	}
};

export default EVENT;
