// @flow

import React, {useEffect} from "react";
import PriceOverviewContainer from "../../../container/checkout/dataSummaryBox/PriceOverviewContainer";
import type {translate} from "../../../i18next";
import type {PaymentFacility, PaymentMethod} from "../../../api/PaymentApi";
import "./Payment.scss";
import {PAYMENT_METHODS, PAYMENT_PROVIDER} from "../../../models/PaymentRequests";
import {toast} from "react-toastify";
import Loading from "../../layout/Loading";

type PaymentProps = {
	savePayment: string => void,
	paymentMethods: PaymentMethod[],
	paymentFacility: PaymentFacility,
	paymentErrors: {},
	isAllShipping: boolean,
	sum: number,
	getPaymentProvider: PaymentMethod => void,
	orderIsCreated: boolean,
	orderPayedFullyByCoupon: boolean,
	initPayment: () => void,
	t: translate,
};

// https://developer.paypal.com/docs/checkout/standard/customize/standalone-buttons/
export const getFundingSourceByPayment = (paymentMethod: string) => {
	switch (paymentMethod) {
		case PAYMENT_METHODS.PAY_LATER:
			return "paylater";
		case PAYMENT_METHODS.PRZELEWY24:
			return "p24";
		case PAYMENT_METHODS.CARD:
			return "card";
		case PAYMENT_METHODS.SEPA:
			return "sepa";
		default:
			return "paypal";
	}
};


export const payPalPaymentEligible = (method: PaymentMethod): boolean => {
	const isFundingEligible = window.paypal && window.paypal.isFundingEligible && window.paypal.isFundingEligible(getFundingSourceByPayment(method.paymentMethod));
	const isFundingEligibleCapture = window.paypal_capture && window.paypal_capture.isFundingEligible && window.paypal_capture.isFundingEligible(getFundingSourceByPayment(method.paymentMethod));

	return PAYMENT_PROVIDER.PAYPAL !== method.paymentProvider.toLowerCase() || isFundingEligible || isFundingEligibleCapture;
};

const Payment = ({
					 getPaymentProvider,
					 initPayment,
					 isAllShipping,
					 orderIsCreated,
					 orderPayedFullyByCoupon,
					 paymentErrors,
					 paymentFacility,
					 paymentMethods,
					 t
				 }: PaymentProps) => {

	useEffect(() => {
		if (orderPayedFullyByCoupon) {
			initPayment();
		}
	}, [orderPayedFullyByCoupon, initPayment]);

	useEffect(() => {
		if (Object.keys(paymentErrors).length > 0) {
			toast.error(t("cart.paymentMethods.assignPaymentError"));
		}
	}, [paymentErrors, t]);

	const handleSubmit = (method: PaymentMethod) => {
		getPaymentProvider(method);
	};

	const isEqual = (method: PaymentMethod, facility: PaymentFacility) => {
		return (method.paymentMethod || "").toLowerCase() === ((facility || {}).paymentMethod || "").toLowerCase();
	};

	const renderPaymentMethods = (method: PaymentMethod) => {
		if (paymentErrors[method.paymentMethod] === true || !payPalPaymentEligible(method)) {
			return <div key={method.paymentMethod} className="hpm-paymentFacility">
				<button type={"button"} disabled={true} className={"hpm-prepaidButton"}>
					{t(`cart.paymentMethods.${method.paymentMethod}`)} {paymentErrors[method.paymentMethod]}
				</button>
			</div>;
		}
		return <div key={method.paymentMethod}
					className={`hpm-paymentFacility ${isEqual(method, paymentFacility) ? " selected" : ""}`}>
			<button type={"button"} onClick={() => handleSubmit(method)} className={"hpm-prepaidButton"}>
				{t(`cart.paymentMethods.${method.paymentMethod}`)}
			</button>
		</div>;
	};

	return (
		<div className={"hpm-payment"}>
			<div className={"hpm-adressContainer"}>
				<div className="hpm-mainContentBox">
					<div className={"hpm-adressTitle"}>{t("cart.paymentMethods.paymentMethodCTA")}</div>
					{(orderIsCreated && !orderPayedFullyByCoupon)
						? <div className="hpm-menu hpm-payment">
							{paymentMethods
								.filter(method => method.paymentMethod !== PAYMENT_METHODS.PAYPAL_EXPRESS)
								.filter(method => method.paymentMethod !== PAYMENT_METHODS.CASH || !isAllShipping)
								.map(method => renderPaymentMethods(method))
							}
						</div>
						: <Loading/>
					}

				</div>
				<div className="hpm-dataSummaryBox">
					<PriceOverviewContainer paymentFacility={paymentFacility}/>
				</div>
			</div>
		</div>
	);
};

export default Payment;
